import React from "react";
import { AnimationControls, motion } from "framer-motion";
import { Title, Subtitle } from "../../styles";
import { EntryAnimationWrapper } from "../EntryAnimationWrapper";

interface Props {
  animationControls: AnimationControls;
  children: React.ReactNode;
}

export function LevelsWrapper({ animationControls, children }: Props) {
  return (
    <motion.div animate={animationControls}>
      <EntryAnimationWrapper>
        <Title>Dance Level</Title>
        <Subtitle>What level are you at right now?</Subtitle>
        {children}
      </EntryAnimationWrapper>
    </motion.div>
  );
}
